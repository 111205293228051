import React, {useState, useRef, useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import useSWR from 'swr';
import {getApplicationPostProcessors} from '../../../api/postprocessor/GetApplicationPostProcessors';
import {DeploymentDetailsInfo} from '../../../types/deployment/DeploymentDetailsInfo';
import {Project} from '../../../types/project/Project';
import {useApplicationPostProcessor} from '../../PrePostProcessing/PostProcessing/useApplicationPostProcessor';
import {usePostProcessingScript} from '../../PrePostProcessing/PostProcessing/usePostProcessingScript';
import {ClassificationResult} from '../ClassificationResult';
import {
  DeploymentPostProcessingParameters,
  RealtimePostProcessingParameters,
  RealtimePreProcessingParameters,
  TestParametersAccordion,
} from '../DeployConfigPanel/TestDeployParameters';
import {SegmentationResult} from '../SegmentationResult';
import {DetectionResult, Image} from '../DetectionResult';
import {ISPResult} from '../ISPResult';
import {NoDeployment} from '../NoDeployment';
import {setStreamingTask, TASK_STATUS, useDeploy} from '../useDeploy';
import {VideoStream} from '../VideoStream';
import {PoseDetectionResult} from '../PoseDetectionResult';
import {GridSkeleton} from '../../Skeleton';
import {useStudioMode} from '../../StudioMode/StudioModeProvider';
import {RootState} from '../../../store';
import Waiting from '../../../base-components/StudioWaiting/Waiting';
import './TestInferenceResults.scss';
import {UnparsedJson} from '../../../types/utility/UnparsedJson';
import {DeploymentDetectionResult} from '../../../types/deployment/DeploymentDetectionResult';
import {DeploymentDetectionResultStats} from '../../../types/deployment/DeploymentDetectionResultStats';
import URL, {MODEL_SUB_OP} from '../../../config/url';
import {startCase, toLower} from 'lodash';

export type TestInferenceResultsProps = {
  deployment: DeploymentDetailsInfo | null;
  hasTransformation: boolean;
  isTaskInProgress: boolean;
  isStreamingInference: boolean;
  projectData: Project;
};

export const TestInferenceResults = ({
  deployment,
  hasTransformation,
  isStreamingInference,
  isTaskInProgress,
  projectData,
}: TestInferenceResultsProps) => {
  const intl = useIntl();
  const [search] = useState('');
  const videoDataContainerRef = useRef<HTMLDivElement>(null);
  const [{streamingTask, deployConfig}, dispatch] = useDeploy();
  const projectId = projectData.id;
  const applicationId = deployConfig.deploymentId;
  const {mode} = useStudioMode();
  const serverConfig = useSelector((state: RootState) => state.config);
  const isFetchingStreamingInference = useMemo(
    () => streamingTask.status === TASK_STATUS.IN_PROGRESS,
    [streamingTask?.status]
  );

  const {data: postProcessorsResponse} = useSWR(
    projectId && applicationId
      ? [projectId, applicationId, '/postprocessor/get/application']
      : null,
    () => getApplicationPostProcessors({projectId, applicationId})
  );

  const postProcessors = postProcessorsResponse?.body || [];
  const browserPostProcessor = postProcessors.find(
    postProcessor =>
      postProcessor.executionContainer === 'BROWSER' &&
      postProcessor.postProcessorType === 'APPLICATION'
  );
  const filePaths = browserPostProcessor?.filePaths || [];
  const fileName = filePaths.find(path => path !== 'metadata.yaml');

  const module = usePostProcessingScript({
    projectId,
    id: applicationId,
    hasPostProcessors:
      projectData.applications[applicationId]?.postProcessors !== undefined,
    fileName,
    postProcessorType: 'APPLICATION',
    executionContainer: 'BROWSER',
    viewOnly: true,
  });

  useApplicationPostProcessor({
    module,
    type: 'videoData',
    data: streamingTask.data,
    dataContainer: videoDataContainerRef.current,
  });

  const modelPurpose = projectData?.model?.modelPurpose || projectData?.dataSet?.purpose;
  const inferenceResults: UnparsedJson | undefined = useMemo(
    () => deployment?.inferenceResults,
    [deployment?.inferenceResults]
  );

  const parsedData: Image[] | undefined = useMemo((): Image[] | undefined => {
    if (inferenceResults && deployment && inferenceResults && inferenceResults !== '{}') {
      const data: DeploymentDetectionResult[] = JSON.parse(inferenceResults);

      const transformed: Image[] = data.map(result => {
        let stats: DeploymentDetectionResultStats[] = result.stats || [];
        return {
          url: `${URL.DATASET(
            projectId,
            MODEL_SUB_OP.GET_BY_ID
          )}?id=${result.filename?.replace(',', '%252C')}&deploymentId=${
            deployment.id
          }&dataPurpose=INPUT&cachebust=${Date.now()}`,
          title: result.filename ?? '',
          data:
            stats?.map(item => ({
              label: startCase(toLower(item.label)),
              bbox: item.bbox,
              progress: item.progress,
            })) ?? [],
        };
      });
      return transformed;
    }
  }, [inferenceResults, deployment, projectId]);

  if (!isTaskInProgress && !deployment) {
    return (
      <NoDeployment
        imageUrl="images/emptyStateNoResults.png"
        message={<FormattedMessage id="test.resultsWillShow" />}
      />
    );
  }

  if (isStreamingInference) {
    if (isFetchingStreamingInference) {
      return (
        <div className="test-inference-results__full-size-container">
          <Waiting
            diameter={100}
            className="test-inference-results__fetching-streaming-inference-waiting"
            loadingText={
              <>
                <h4>{intl.formatMessage({id: 'test.streamingInferenceLoadingTitle'})}</h4>
                <p className="bodyLargeShort">
                  {intl.formatMessage({id: 'test.streamingInferenceLoadingSubTitle'})}
                </p>
              </>
            }
          />
        </div>
      );
    }

    const {status, userStream, remoteStream, receiveOnly} = streamingTask;

    return (
      <section className="test-inference-results__video-container">
        <div className="test-inference-results__video">
          <div className="test-inference-results__video-main">
            <VideoStream
              receiveOnly={receiveOnly}
              status={status}
              userStream={userStream}
              remoteStream={remoteStream}
            />
            {/* Container that is populated with BROWSER post processor */}
            <div id="test-inference-results-video-data" ref={videoDataContainerRef} />
          </div>
          {mode === 'LITE' && serverConfig.maxJobRuntimeSeconds && (
            <p>
              {intl.formatMessage(
                {id: 'studioLite.inference.duration.warning'},
                {seconds: serverConfig.maxJobRuntimeSeconds}
              )}
            </p>
          )}
          {applicationId && (
            <>
              <TestParametersAccordion
                title={intl.formatMessage({id: 'test.form.rtpreprocessing'})}
                className="test-inference-results__accordion"
              >
                <RealtimePreProcessingParameters
                  className="test-inference-results__form"
                  projectId={projectId}
                  applicationId={applicationId}
                  renderField={(field, param) => (
                    <div className="test-inference-results__field" key={param.name}>
                      {field}
                    </div>
                  )}
                  emptyMessage={
                    <div className="test-inference-results__empty">
                      {intl.formatMessage({id: 'test.form.preprocessing.empty'})}
                    </div>
                  }
                  onChange={parameters =>
                    dispatch(
                      setStreamingTask({realtimePreProcessorParameters: parameters})
                    )
                  }
                />
              </TestParametersAccordion>
              <TestParametersAccordion
                title={intl.formatMessage({id: 'test.form.rtpostprocessing'})}
                className="test-inference-results__accordion"
              >
                <RealtimePostProcessingParameters
                  className="test-inference-results__form"
                  projectId={projectId}
                  applicationId={applicationId}
                  renderField={(field, param) => (
                    <div className="test-inference-results__field" key={param.name}>
                      {field}
                    </div>
                  )}
                  emptyMessage={
                    <div className="test-inference-results__empty">
                      {intl.formatMessage({id: 'test.form.postprocessing.empty'})}
                    </div>
                  }
                  onChange={parameters =>
                    dispatch(
                      setStreamingTask({realtimePostProcessorParameters: parameters})
                    )
                  }
                />
              </TestParametersAccordion>
              <DeploymentPostProcessingParameters
                className="test-inference-results__form"
                disabledForm
                projectId={projectId}
                applicationId={deployConfig.deploymentId}
                renderField={(field, param) => (
                  <div className="test-inference-results__field" key={param.name}>
                    {field}
                  </div>
                )}
                titleMessageId="test.form.deploypostprocessing"
                wrapperClassName="test-inference-results__accordion"
                onChange={() => {}}
              />
            </>
          )}
        </div>
      </section>
    );
  }

  if (isTaskInProgress) {
    return (
      <div className="test-inference-results__full-size-container">
        <GridSkeleton
          tileHeight={128}
          tileWidth={128}
          tileMarginX={14}
          tileMarginY={14}
        />
      </div>
    );
  }

  if (deployment) {
    // For some reason, backend sends a string instead of JSON.
    // So string comparison is used to verify if results are empty
    const videoInputs = new Set(['WEB_CAMERA', 'IP_CAMERA', 'FILE']);
    const sourceType = deployment?.deploymentInput?.inputSourceType || '-';

    if (
      modelPurpose &&
      inferenceResults &&
      inferenceResults !== '{}' &&
      !videoInputs.has(sourceType)
    ) {
      return (
        <>
          <div className="test-inference-results__images">
            {modelPurpose === 'Classification' && (
              <ClassificationResult
                search={search}
                data={inferenceResults}
                projectId={projectId}
                deploymentId={deployment.id}
              />
            )}
            {modelPurpose === 'Segmentation' && (
              <SegmentationResult
                search={search}
                data={inferenceResults}
                projectId={projectData.id}
                deploymentId={deployment.id}
              />
            )}
            {modelPurpose === 'PoseDetection' && (
              <PoseDetectionResult
                search={search}
                data={inferenceResults}
                projectId={projectData.id}
                deploymentId={deployment.id}
              />
            )}
            {modelPurpose === 'Detection' && parsedData && (
              <DetectionResult
                search={search}
                searchEnabled={true}
                data={parsedData}
                projectId={projectId}
                deploymentId={deployment.id}
              />
            )}
          </div>
        </>
      );
    }

    if (hasTransformation) {
      return <ISPResult projectId={projectId} deploymentId={deployment.id} />;
    }

    return (
      <NoDeployment
        imageUrl="images/emptyStateNoResults.png"
        message={<FormattedMessage id="test.videoStreamingHasFinished" />}
      />
    );
  }

  return null;
};
